/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function data() {
  const Employee = ({ image, name, startDate }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{startDate}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "employee", accessor: "employee", align: "left" },
      { Header: "CY Salary", accessor: "salaryCY", align: "center" },
      { Header: "CYTD Salary", accessor: "salaryYTD", align: "center" },
      { Header: "Bonus Fee", accessor: "bonus", align: "center" },
      { Header: "XMas Bonus", accessor: "xmas", align: "center" },
      { Header: "Salary + Bonus", accessor: "salPlusBonus", align: "center" },
      { Header: "Pension", accessor: "pension", align: "center" },
      { Header: "Health Insurance Premium", accessor: "health", align: "center" },
      { Header: "Total Compensation", accessor: "totComp", align: "center" },
      { Header: "PY Total Compensation", accessor: "pyTotComp", align: "center" },
      { Header: "CY Vacation/Sick Time", accessor: "sick", align: "center" },
      { Header: "Next Year Salary", accessor: "nySal", align: "center" },
    ],

    rows: [
      {
        employee: <Employee name="Alice" startDate="December 31, 2001" />,
        salaryCY: "$63,000",
        salaryYTD: "$0",
        bonus: "$0",
        xmas: "$7,000",
        salPlusBonus: "$70,000",
        pension: "$5,425",
        health: "$4,792",
        totComp: "$80,217",
        pyTotComp: "$52,350",
        sick: "24",
        nySal: "$65,000",
      },
      {
        employee: <Employee name="Bob" startDate="December 31, 2022" />,
        salaryCY: "$100,000",
        salaryYTD: "$80,000",
        bonus: "$15,500",
        xmas: "$15,000",
        salPlusBonus: "$100,500",
        pension: "$5,000",
        health: "$4,000",
        totComp: "$80,217",
        pyTotComp: "$52,350",
        sick: "24",
        nySal: "$65,000",
      },
      {
        employee: <Employee name="Charlie" startDate="December 31, 2023" />,
        salaryCY: "$63,000",
        salaryYTD: "$0",
        bonus: "$0",
        xmas: "$7,000",
        salPlusBonus: "$70,000",
        pension: "$5,425",
        health: "$4,792",
        totComp: "$80,217",
        pyTotComp: "$52,350",
        sick: "24",
        nySal: "$65,000",
      },
      {
        employee: <Employee name="Dunkin" startDate="December 31, 2020" />,
        salaryCY: "$63,000",
        salaryYTD: "$0",
        bonus: "$0",
        xmas: "$7,000",
        salPlusBonus: "$70,000",
        pension: "$5,425",
        health: "$4,792",
        totComp: "$80,217",
        pyTotComp: "$52,350",
        sick: "24",
        nySal: "$65,000",
      },
      // {
      //   employee: <Employee image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
      //   function: <Job title="Programator" description="Developer" />,
      //   status: (
      //     <MDBox ml={-1}>
      //       <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
      //     </MDBox>
      //   ),
      //   employed: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       11/01/19
      //     </MDTypography>
      //   ),
      //   action: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       Edit
      //     </MDTypography>
      //   ),
      // },
      // {
      //   employee: <Employee image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
      //   function: <Job title="Programator" description="Developer" />,
      //   status: (
      //     <MDBox ml={-1}>
      //       <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
      //     </MDBox>
      //   ),
      //   employed: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       24/12/08
      //     </MDTypography>
      //   ),
      //   action: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       Edit
      //     </MDTypography>
      //   ),
      // },
      // {
      //   employee: <Employee image={team3} name="Richard Gran" email="richard@creative-tim.com" />,
      //   function: <Job title="Manager" description="Executive" />,
      //   status: (
      //     <MDBox ml={-1}>
      //       <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
      //     </MDBox>
      //   ),
      //   employed: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       04/10/21
      //     </MDTypography>
      //   ),
      //   action: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       Edit
      //     </MDTypography>
      //   ),
      // },
      // {
      //   employee: <Employee image={team4} name="Miriam Eric" email="miriam@creative-tim.com" />,
      //   function: <Job title="Programator" description="Developer" />,
      //   status: (
      //     <MDBox ml={-1}>
      //       <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
      //     </MDBox>
      //   ),
      //   employed: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       14/09/20
      //     </MDTypography>
      //   ),
      //   action: (
      //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //       Edit
      //     </MDTypography>
      //   ),
      // },
    ],
  };
}
